@import "../1-tools/variables";

.header-container {
	width: 100%;
	height: 55px;
	// background: $linear-green-dark;
	background: rgba(126, 148, 180, 0.51);
	position: absolute;
	backdrop-filter: blur(20px);
	top: 0px;
	display: flex;
	align-items: center;
	color: $white;
	padding-left: 4rem;
	padding-right: 4rem;
	z-index: 3;
	overflow: hidden;

	@media only screen and (min-width: 1024px) and (max-width: 1440px) {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	@include media-breakpoint-down(md) {
		position: fixed;
	}

	.logo {
		line-height: inherit;
		white-space: nowrap;
		display: flex;
		position: absolute;
		width: 100%;
		justify-content: center;
		left: 0px;
	}

	.learning-path-wrapper {
		position: relative;

		@include media-breakpoint-up(lg) {
			&::after {
				content: "";
				display: block;
				height: 35px;
				width: 35px;
				border-radius: 50%;
				background: rgba(2, 43, 77, 0.38);
				backdrop-filter: blur(20px);
				position: absolute;
				left: -9px;
				top: -8px;
				z-index: -1;
			}
		}
	}

	svg {
		height: 1rem;

		&.learning-path {
			height: 19px;
			// overflow: visible;
			cursor: pointer;
		}
	}

	.hamburger-div {
		cursor: pointer;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 9px;
		right: 32px;
	}

	.notification-on {
		border: 3px solid yellow;
		border-radius: 50px;
	}

}

.notification-round-btn {
	position: absolute;
	height: 9px;
	width: 10px;
	background-color: red;
	right: -2px;
	top: -3px;
	border-radius: 50px;
}

.guest-header-container {
	width: 100%;
	height: 60px;
	background: $linear-green-dark;
	position: fixed;
	top: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	padding-left: 7rem;
	padding-right: 6rem;
	z-index: 3;
	overflow: hidden;

	.logo {
		line-height: inherit;
		white-space: nowrap;
		position: absolute;
		z-index: -1;
		height: 20px;
	}

	@media only screen and (min-width: 1024px) and (max-width: 1440px) {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	@include media-breakpoint-down(md) {
		padding: 0rem;
	}
}

.header-pages-ul {
	margin-left: 28px;
	margin-bottom: auto;
	margin-top: auto;
	list-style: none;
	height: fit-content;
	align-items: center;
	display: flex;
	z-index: 1;
	position: relative;
	border-radius: 15px;
	background: rgba(2, 43, 77, 0.41);
	backdrop-filter: blur(20px);

	@include media-breakpoint-down(md) {
		background: transparent;
		height: 100%;
	}

	li {
		display: inline;

		@include media-breakpoint-down(md) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		@media screen and (max-width: 500px) {
			padding: 0 !important;
			width: 129px;
		}

		@media screen and (max-width: 380px) {
			padding: 0px !important;
		}
		&:active,
		&:focus,
		& > a:active,
		& > a:focus {
			outline: none;
		}

		a {
			text-decoration: none;
			color: #022b4d;

			@include media-breakpoint-down(md) {
				color: #fff;
			}

			.pill {
				display: flex;
				align-items: center;
				border-radius: 15px;
				padding: 0.4rem 0.6rem;

				svg {
					height: 1.2rem;
				}

				span {
					display: none;
					font-family: $montserrat-semi;
					font-weight: 500;
					letter-spacing: normal;
					font-stretch: normal;
					padding-left: 0.5rem;
					font-size: 15px;
				}
			}

			&:hover {
				.pill {
					// background: rgba(#fff, 0.25);
					cursor: pointer;
				}
			}
		}

		a.active {
			text-decoration: none;
			// color: white;

			.pill {
				padding: 0.4rem 0.6rem;
				background: linear-gradient(90deg, #ffffff 6.25%, rgba(227, 227, 227, 0.84) 100%);

				svg path {
					fill: #022b4d;

					@include media-breakpoint-down(md) {
						fill: #fff;
					}
				}

				span {
					display: inherit;
				}
			}

			&::after {
				content: none;
			}
		}
	}

	li:nth-child(2) {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}

@include media-breakpoint-down(md) {
	.header-container {
		padding: 1rem;
		// background: $medium-green;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		top: auto;
		bottom: 0px;
		align-items: center;

		svg.logo,
		svg.learning-path {
			display: none;
		}

		.header-pages-ul {
			margin-left: auto;
			width: 100%;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;

			li {
				padding: 1rem 4rem;

				a .pill {
					padding: 0px;
					background: transparent;
					flex-direction: column;
					align-items: center;

					svg {
						height: 1.5rem;
						opacity: 0.65;
					}

					span {
						display: block;
						font-size: 11px;
						padding-left: 0px;
						margin-top: 3px;
						opacity: 0.65;
						@media screen and (max-width: 320px) {
							font-size: 10px;
						}
					}
				}

				a.active .pill svg,
				a.active .pill span {
					opacity: 1;
				}

				@include media-breakpoint-down(sm) {
					padding: 1rem 1.5rem;
				}
				@media only screen and (max-width: 375px) and (orientation: portrait) {
					padding: 1rem 1.2rem;
				}
				@media only screen and (max-width: 360px) and (orientation: portrait) {
					padding: 1rem 1.1rem;
				}
				@media only screen and (max-width: 320px) and (orientation: portrait) {
					padding: 1rem 0.7rem;
				}
			}

			svg {
				display: inline !important;
			}
		}
	}
}

// IPAD PRO SPECIFIC MEDIA QUESRIES

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) {
	.header-container {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.header-pages-ul {
		li {
			padding-left: 1rem;
			padding-right: 0.5rem;
		}
	}

	.header-pages-ul li a.active .pill {
		display: flex;
		padding: 0.4rem 0.5rem;
		border-radius: 12px;

		svg {
			height: 1rem;
		}

		span {
			font-size: 14px;
			padding-left: 5px;
		}
	}
}

.mobile-top-header-container {
	@extend .header-container;
	top: 0px;
	bottom: auto;
	border-radius: 0px;
	background: rgba(126, 148, 180, 0.51);
	color: $black;
	height: auto;

	@media screen and (max-width: 600px) {
		padding-bottom: 5px;
		padding-top: 0px;
		height: 50px;
	}

	svg {
		height: 1.1rem;
		display: block !important;

		&.learning-path {
			padding: 0px;
			// margin-bottom: 0.5rem;
			height: 1.5rem;
			cursor: pointer;

			// path {
			// 	stroke: #000;
			// }
		}

		&.navbar-hamburger-icon {
			padding: 0px;
			margin: 0px;
			cursor: pointer;

			// path {
			// 	fill: #000;
			// }
		}
	}

	.logo {
		height: 1.3rem;
		white-space: nowrap;
		margin-left: 50%-20;
		padding: 0px;
		margin-right: auto;
		// margin-left: auto;
		position: relative;
		width: auto;

		// path {
		// 	fill: #000;
		// }

		@include media-breakpoint-down(md) {
			margin-left: auto;
		}

		@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
			margin-left: 50%-9;
		}
	}
}

.mobile-top-header-container.pricepopupOn {
	@include media-breakpoint-down(md) {
		margin-top: 60px;
		padding: 0.7rem 0.6rem;
		box-shadow: 0px 0.5px 4px 0.5px rgba(0, 0, 0, 0.3);
	}

	@include media-breakpoint-down(sm) {
		margin-top: 55px;
	}
}

.header-progress-container {
	@extend .header-container;
	// background: lightblue;
	height: 22px;
	top: 55px;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 13px;
	justify-content: space-between;

	&.pricepopupOn {
		top: 99px;
	}

	a,
	a:hover {
		color: #022b4d;
		display: contents;

		span,
		div {
			cursor: pointer;
		}
	}
}

.header-progress-bar {
	border-radius: 30px;
	height: 7px !important;
	width: 50% !important;
	margin-left: 7px;
	font-size: 10px !important;
	background-color: rgba(2, 43, 77, 0.41) !important;

	@include media-breakpoint-down(sm) {
		height: 5px !important;
		width: 150px !important;
		margin-left: 0px;
	}

	.progress-color {
		// background: linear-gradient(to left, #ffba30 0%, #f7b22e -44%, #e09e2a 70%, #cc8c23 108%);
		background: linear-gradient(90deg, rgba(2, 43, 77, 0.5) 0%, rgba(196, 196, 196, 0.8) 100%);
		height: 100%;
	}
	&.black > .progress-color {
		// margin-left: 2px;
		color: $dark-blue !important;
	}
}

.mobile-header-progress-container {
	@extend .mobile-top-header-container;
	height: 30px;
	top: 50px;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 13.5px;
	// box-shadow: 0 8px 24px -13px;

	// @media screen and (max-width: 500px) {
	// 	top: 60px;
	// }

	&.pricepopupOn {
		top: 50px;
	}

	@include media-breakpoint-down(sm) {
		font-size: 10px;
	}

	.sm-flex-column {
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			padding-bottom: 7px;
		}
	}

	a,
	a:hover {
		color: $dark-blue;
		display: contents;

		span,
		div {
			cursor: pointer;
		}
	}
}

.mobile-top-header-container.nobg {
	background-color: transparent;
	color: #fff;

	svg {
		// fill: #fff;
		// stroke: #fff;
		path {
			fill: #fff;
			stroke: #fff;
		}
	}
}

/*      ====  SIDE MENU  ====      */
.sidemenu-blank-div {
	width: 100%;
	height: 100%;
	z-index: 4;
	position: fixed;
	display: flex;
	top: 0px;
	left: 0px;
	background: rgba($pure-black, 0.6);
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s;
}

.side-menu {
	height: 100%;
	width: 0%;
	position: fixed;
	z-index: 999;
	top: 0;
	right: 0;
	// background: linear-gradient(to bottom, #30374e 0%, #15e5c2 168%), linear-gradient(to top, #15e5c2, #22a246);
	background: linear-gradient(180deg, rgba(2, 43, 77, 0.6) 0%, rgba(28, 137, 118, 0.552) 100%);
	backdrop-filter: blur(20px);
	overflow: hidden;
	padding-top: 9%;
	transition: 0.5s;
	font-family: $montserrat-semi;
	letter-spacing: 0.4px;

	@include media-breakpoint-down(sm) {
		padding-top: 28%;
	}
}

.side-menu .sidenav-link {
	margin-top: 2rem;
	margin-bottom: 2.25rem;
	padding-left: 12%;
	text-decoration: none;
	color: $white;
	display: flex;
	align-items: center;
	font-family: $montserrat-regular;
	transition: 0.3s;
	opacity: 0.9;
	font-size: 19px;
	font-weight: 600;
	cursor: pointer;

	@include media-breakpoint-down(md) {
		padding: 0rem 3rem;
		margin: 1.6rem 0rem;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		padding-left: 2rem;
	}

	&:hover {
		opacity: 1;
	}

	svg {
		height: 1.5rem;
		margin-right: 1rem;
		flex-basis: 8%;

		path {
			stroke: #fff;
		}

		path:last-child {
			fill: #fff;
		}

		@include media-breakpoint-down(sm) {
			flex-basis: 11%;
		}
	}
	.sidebar-refer-icon {
		path:last-child {
			fill: #000;
		}
		height: 20px;
		width: 14px;
	}

	.sidebar-bookmark-icon {
		opacity: 0.4;
		height: 20px;
		width: 14px;
	}

	&.contact {
		font-size: $font-size-16;
		margin-bottom: 0.8rem;
		margin-top: 4rem;
	}

	&.logout {
		font-size: $font-size-16;
		margin-top: 4rem;

		@include media-breakpoint-down(sm) {
			margin-top: 3rem;
		}
	}
}

.sidenav-link.active {
	color: $pure-white;
}

.side-menu .sidenav-link:hover {
	color: #f1f1f1;
}

.side-menu .closebtn {
	position: absolute;
	right: 7%;
	color: $white;
	top: 0.5%;
	font-size: 2.2rem;
	cursor: pointer;
}

.side-menu .side-menu-logo-vector {
	position: absolute;
	width: 105%;
	height: 85%;
	top: 5%;
	left: 0;
	z-index: -1;
}

.side-menu .side-menu-careerninja {
	font-family: $montserrat-semi;
	font-size: $font-size-26;
	position: absolute;
	bottom: 2%;
	right: 6%;
	color: $white;
}

.side-menu.open {
	width: 25%;

	@media screen and (width: 1024px) {
		width: 35%;
	}

	@include media-breakpoint-down(md) {
		width: 45%;
		padding-top: 20%;
	}

	@include media-breakpoint-down(sm) {
		width: 80%;
		padding-top: 35%;
	}
}

.sidemenu-blank-div.open {
	visibility: visible;
	opacity: 1;
}

/*      ====  LEARNING PATH SIDEBAR  ====      */

.lp-sidebar {
	height: 100vh;
	width: 30%;
	transform: translateX(-100%);
	transition: transform 0.5s;
	position: absolute;
	z-index: 999;
	top: 0;
	left: 0;
	// background: linear-gradient(to top, #086788, #30374e);
	background: linear-gradient(180deg, rgba(2, 43, 77, 0.6) 0%, rgba(28, 137, 118, 0.552) 100%);
	backdrop-filter: blur(20px);
	overflow-y: auto;
	overflow-x: hidden;
	padding: 40px 14px 20px 40px;
	transition: 0.5s;
	font-family: $montserrat-regular;
	color: $pure-white;
	letter-spacing: 0.4px;
	scrollbar-color: gray #086788;

	@media screen and (width: 1024px) {
		width: 40%;
	}

	@include media-breakpoint-down(md) {
		width: 45%;
		padding: 40px 30px 20px 20px;
	}

	@include media-breakpoint-down(sm) {
		width: 85%;
		padding: 40px 30px 20px 20px;
	}

	&.open {
		transform: translateX(0%);
	}
}

.lp-sidebar::-webkit-scrollbar {
	width: 8px;
}

.lp-sidebar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.lp-sidebar::-webkit-scrollbar-thumb {
	background-color: rgba($pure-white, 0.4);
	border-radius: 4px;
	outline: none;
}

.lp-sidebar-title {
	display: flex;
	font-family: $montserrat-regular;
	font-size: 22px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	cursor: default;
	@media screen and (max-width: 600px) {
		font-size: 18px;
	}

	svg {
		height: 1.3rem;
		margin-right: 7px;
		margin-top: 5px;
		overflow: visible !important;
	}
}

.lp-sidebar-blank-div {
	height: 100%;
	width: 100%;
	z-index: 4;
	top: 0px;
	left: 0px;
	position: fixed;
	background: rgba($pure-black, 0.6);
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s;
}

.lp-sidebar-blank-div.open {
	opacity: 1;
	visibility: visible;
}

// VERSION 1 COMPONENT STYLES

.react-tooltip-lite {
	background: #333;
	color: white;
	border-radius: 4px;
}

.react-tooltip-lite-arrow {
	border-color: #333;
}

.profile-image-style {
	border-radius: 50%;
	height: 28px;
	width: 28px;
}

.profile-name-navbar{
	font-size: 12.5px;
}

.tool-tip {
	width: 91px;
	position: absolute;
	left: -25px;
	background: black;
	width: 100px;
	font-size: 12px;
	color: white;
	border-radius: 10px;
	padding: 5px;
	display: flex;
	justify-content: space-between;

	span {
		font-size: 10px;
		color: white;
	}
}

#navbar .header-gamification-ul {
	list-style: none;
	align-items: center;
	display: flex;
	margin: 0px 22px 0px 42px;
	cursor: default;
	color: #fff;

	li {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-family: $montserrat-semi;
		// &:nth-child(even) {
		margin-left: 7px;
		margin-right: 7px;

		// }
		& > div {
			display: flex;
			height: 100%;
		}

		// & .current-points{}
	}

	@include media-breakpoint-down(md) {
		height: auto;
		padding-left: 1.5rem;
		margin-bottom: 2rem;
		margin-left: 22px;
	}
	
	@include media-breakpoint-down(sm) {
		padding-left: 0rem;
		margin-left: 22px;
	}

	@media screen and (width: 1024px) {
		margin: 0px 22px 0px 32px;
	}
}

.icon-badge {
	width: 30px !important;
	height: 30px !important;
	margin-right: 4px;
}

.icon-badge.header-streak-icon {
	margin-right: 0px;
}

.messageout-enter {
	position: absolute;
	top: 0;
	left: calc(13% + 5px);
	right: calc(13% + 5px);
	opacity: 0.01;
	transform: translateY(0%) scale(0.01);
}

/* ending ENTER animation */
.messageout-enter-active {
	opacity: 1;
	transform: translateY(0%) scale(1);
	transition: all 1000ms ease-in-out;
}

/* starting EXIT animation */
.messageout-exit {
	opacity: 1;
	transform: scale(1.01);
}

/* ending EXIT animation */
.messageout-exit-active {
	opacity: 0;
	transform: scale(4);
	transition: all 900ms ease-in-out;
}

.header-pricing-btn {
	padding: 7px 10px 7px 10px;
	font-weight: 700;
	color: #fff;
	border: none;
	outline: none;
	text-decoration: none;
	border-radius: 10px;
	background: linear-gradient(to left, #ffba30 0%, #f7b22e -44%, #e09e2a 70%, #cc8c23 108%);
	font-size: 14px;
	transition: all 0.2s ease-in-out;
	margin-left: auto;
	margin-right: 6rem;
	z-index: 1;

	&:focus {
		outline: none;
	}

	&:hover {
		color: #fff;
		box-shadow: 0 5px 12px 0 rgba(48, 45, 58, 0.3);
	}

	@media screen and (width: 1024px) {
		padding: 5px 7px 5px 7px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 2rem;
		margin-left: 50px;
		margin-right: 0px;
	}

	@include media-breakpoint-down(sm) {
		margin-left: 2rem;
		margin-bottom: 2rem;
		margin-left: 12%;
		margin-right: 0px;
	}
}

.goldColor {
	background: linear-gradient(to left, #ffba30 0%, #f7b22e -44%, #e09e2a 70%, #cc8c23 108%);
}

// progress bar scss
.RSPBprogressBar {
	width: 75%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 7px;
}

.progress-bar-days {
	height: 130px;
	display: block;
	margin-top: 30px;
	border: 1px solid #26bba2;
	border-radius: 15px;
	background: white;
	margin-bottom: 30px;
	& :hover {
		cursor: pointer;
	}

	&.pricepopupOn {
		@media screen and (min-width: 340px) and (max-width: 768px) {
			margin-top: 90px !important;
		}
	}

	@media screen and (min-width: 767px) and (max-width: 768px) {
		height: 115px;
		margin-bottom: 20px;
		margin-top: 60px;
	}

	@include media-breakpoint-down(sm) {
		height: 115px;
	}
}

.days-progress-main {
	// box-shadow: 0px 0px 17px 0px #26bba2;
	padding-bottom: 20px;
	margin-top: 6px;
	position: relative;

	@include media-breakpoint-down(sm) {
		margin-top: 16px;
		padding-bottom: 0px;
	}
}

.days-progress-panel {
	position: absolute;
	width: 50%;
	left: 24.5%;
	height: auto;
	z-index: 2;
	background-color: white;
	top: 200px;
	box-shadow: 3px 0px 20px 3px lightgrey;
}

.expansion-panel-heading {
	padding: 40px;
	margin-bottom: 5px;

	@media screen and (max-width: 767px) {
		padding: 25px;
	}

	p {
		padding-left: 20px;

		@media screen and (max-width: 767px) {
			padding-left: 10px;
		}
	}

	hr {
		border-top: 2px solid gray;
		opacity: 0.6;
	}

	progress {
		margin-left: 15px;
		height: 50px;
		width: 88%;
	}

	progress::-webkit-progress-value {
		background: #26bba2;
	}

	// progress {
	// 	color: #26bba2;
	// }
	progress::-moz-progress-bar {
		background: #26bba2;
	}
}

.RSPBstep {
	top: 0;
	bottom: 0;
}

.expansion-head {
	font-family: $montserrat-bold;
	width: max-content;
	font-size: 30px;
}

.power-user-text {
	text-align: center;
	padding-bottom: 30px;
	font-size: 23px;
	font-weight: 700;
	margin-bottom: 10px;

	@media screen and (min-width: 767px) and (max-width: 768px) {
		font-size: 15px;
	}

	@media screen and (max-width: 500px) {
		font-size: 12px;
		padding-bottom: 10px;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: 20px;
	}
}

.head-panel {
	padding-top: 0px;
}

.task-index {
	width: 31px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 31px;
	border: 1px solid darkgray;
	border-radius: 50px;
	padding-left: 0px !important;
	color: darkgray;

	@media screen and (max-width: 380px) {
		width: 21px;
		height: 21px;
	}
}

.not-done {
	border: 1px solid #26bba2;
	color: #26bba2;
}

.task-row {
	padding-bottom: 20px;
	align-items: center;

	@media screen and (max-width: 676px) {
		padding-bottom: 10px;
	}
}

.task-div {
	margin-left: 10px;
	@media screen and (max-width: 767px) {
		margin-left: 0px;
	}

	.task-head {
		font-size: 20px;

		@media screen and (max-width: 767px) {
			font-size: 12px;
		}

		@media screen and (max-width: 380px) {
			font-size: 8px;
		}
	}

	.head-not-done {
		color: #26bba2;
	}

	.task-desc {
		color: darkgray;

		@media screen and (max-width: 767px) {
			font-size: 12px;
		}

		.bold {
			color: #26bba2;

			@media screen and (max-width: 767px) {
				font-size: 12px;
			}
		}
	}
}

//seminar reminder styles

.bar-text-padding {
	padding-left: 50px;
	padding-right: 50px;
}

.notification-square {
	margin-left: 4rem;
	background-color: #069c8e;
	padding: 20px;
	border-top-right-radius: 23px;
}

.notification-panel-main {
	height: 0%;
	width: 0%;
	position: fixed;
	// transition: 0.5s;
	font-family: "Montserrat-Semi";
	letter-spacing: 0.4px;
	width: 0%;
	visibility: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 75%;
	transition: height 0.5s linear 0.3s;

	@media screen and (max-width: 450px) {
		transition: all 0.5s;
		height: 96%;
	}
	@media screen and (max-width: 360px) {
		height: 88%;
	}
}

.open-panel {
	height: 70%;
	position: fixed;
	visibility: visible;
	width: 30%;
	top: 2%;
	right: 28%;
	z-index: 999;
	@media screen and (max-width: 500px) {
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 87%;
		max-height: 100%;
	}
}

.notification-div {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: white;
	border-radius: 10px;
}

.notification-div:after {
	content: "";
	position: absolute;
	margin-top: -6px;
	margin-left: -5px;
	border-left: 15px solid white; /*This is your color of the arrow*/
	border-top: 15px solid transparent; /*half the height*/
	border-bottom: 15px solid transparent;
	right: -16px;
	top: 345px;
}

.notification-head {
	padding: 15px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid gray;
	background: linear-gradient(to right, #086788 0%, #26bba2 100%);
	p {
		text-transform: uppercase;
		font-size: 27px;
		color: white;
		@media screen and (max-width: 500px) {
			font-size: 20px;
		}
	}
}

.notification-message {
	width: 70%;
	.message {
		font-size: 20px;
		line-height: 1.5rem;
		margin-bottom: 5px;
		@media screen and (max-width: 500px) {
			font-size: 15px;
		}
	}
}

.notification-box {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 18px 12px;
	border-bottom: 1px solid gray;
	cursor: pointer;
}

.white-bg {
	background: white;
}

.notification-time {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	p {
		font-size: 15px;
		color: gray;
		@media screen and (max-width: 500px) {
			font-size: 12px;
		}
	}
}

.time-img {
	width: 20px;
	height: 20px;
	margin-right: 7px;
	margin-top: -5px;
	@media screen and (max-width: 500px) {
		height: 15px;
		width: 15px;
	}
}

.notification-panel {
	background-color: #eff8fc;
	height: 89%;
	overflow-y: scroll;
}

.bottom-border {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.close-notification {
	width: 6%;
	cursor: pointer;
}

.notification-below-section {
	background: #2a85a4;
	@extend .bottom-border;
}

.below-text-div {
	text-align: center;
	padding: 20px;
	border: 1px solid gray;
	cursor: pointer;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	@media screen and (max-width: 500px) {
		padding: 15px;
	}
}

.below-text-div:hover {
	transition: 0.5s;
	border: 1px solid black;
}

.below-text {
	text-transform: uppercase;
	font-weight: 900;
	color: white;
	font-size: 20px;
	font-family: $montserrat-bold;
	cursor: pointer;
	@media screen and (max-width: 500px) {
		font-size: 15px;
	}
}

.below-text:hover {
	color: black;
}

.notification-row {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.notification-calender-img {
	margin-right: 15px;
}

.delete-checkbox {
	height: 25px;
	width: 25px;
	cursor: pointer;
	@media screen and (max-width: 500px) {
		width: 20px;
		height: 20px;
	}
}

.notification-deletion-div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.delete-all {
	margin-top: 4px;
	margin-left: 5px;
}

.notification-btn {
	position: relative;
}

.side-panel-round-btn {
	right: 20px;
	top: -10px;
	height: 0px;
	width: 0px;
	background: transparent;
	color: red;
	font-family: $montserrat-semi !important;
}

.bold-notification-text {
	opacity: 1 !important;
	font-family: $montserrat-semi !important;
}

.cross-btn-notification {
	height: 30px;
	width: 30px;
	margin-left: 15px;
}

.delete-text {
	font-size: 16px;
}
